import { gql } from '@apollo/client';

export const TransferTypes = {
  DEPOSIT: {
    key: 'DEPOSIT',
    label: 'Deposit',
  },
  WITHDRAWAL: {
    key: 'WITHDRAWAL',
    label: 'Withdrawal',
  },
  INTERNAL: {
    key: 'INTERNAL',
    label: 'Internal',
  },
};

export const AccountTypesJ25 = {
  ADOPTION: {
    key: 'ADOPTION',
    label: 'Sponsorship',
    external: false,
  },
  REGIONAL_COORD: {
    key: 'REGIONAL_COORD',
    label: 'Regional Coordinator',
    external: false,
  },
  FIELD_WORKER: {
    key: 'FIELD_WORKER',
    label: 'Field Worker',
    external: false,
  },
  UNALLOCATED: {
    key: 'UNALLOCATED',
    label: 'Unallocated',
    external: false,
  },
  FW_UNALLOCATED: {
    key: 'FW_UNALLOCATED',
    label: 'Unallocated Field Worker',
    external: false,
  },
  RC_UNALLOCATED: {
    key: 'RC_UNALLOCATED',
    label: 'Unallocated Regional Coordinator',
    external: false,
  },
  PP_UNALLOCATED: {
    key: 'PP_UNALLOCATED',
    label: 'Unallocated Payer Donations',
    external: false,
  },
  PROJECT_SPONSORSHIP: {
    key: 'PROJECT_SPONSORSHIP',
    label: 'Holistic Project Sponsorship',
    external: false,
  },
  HOLISTIC: {
    key: 'HOLISTIC',
    label: 'Regional Holistic Projects',
    external: false,
  },
  HOLISTIC_PROJECT: {
    key: 'HOLISTIC_PROJECT',
    label: 'Holistic Project',
    external: false,
  },
  HOLISTIC_PAYBACK: {
    key: 'HOLISTIC_PAYBACK',
    label: 'Holistic Project Payback',
    external: false,
  },
  TRAINING: {
    key: 'TRAINING',
    label: 'Regional Training',
    external: false,
  },
  A_N_D: {
    key: 'A_N_D',
    label: 'A & D',
    external: false,
  },
  DEFAULT: {
    key: 'DEFAULT',
    label: 'Default',
    external: false,
  },
  RECONCILLIATION: {
    key: 'RECONCILLIATION',
    label: 'Reconciliation',
    external: false,
  },
  EX_ADOPTER: {
    key: 'EX_ADOPTER',
    label: 'Sponsor (External)',
    external: true,
  },
  EX_RC: {
    key: 'EX_RC',
    label: 'Regional Coordinator (External)',
    external: true,
  },
  EX_FW: {
    key: 'EX_FW',
    label: 'Field Worker (External)',
    external: true,
  },
  EX_HOLISTIC_PAYOUT: {
    key: 'EX_HOLISTIC_PAYOUT',
    label: 'Holistic Project Payout (External)',
    external: true,
  },
  EX_TRAINING: {
    key: 'EX_TRAINING',
    label: 'Training (External)',
    external: true,
  },
  EX_DEFAULT: {
    key: 'EX_DEFAULT',
    label: 'Default (External)',
    external: true,
  },
};

const accountListAttributes = gql`
  {
    _id
    name
    description
    fundId
    fund {
      _id
      name
    }
    external
    type
    regionCode
    regionName
    peopleGroupId
    peopleGroup {
      _id
      country
      nameAcrossCountries
    }
    regionalCoordId
    regionalCoord {
      _id
      fullName
    }
    fieldWorkerId
    fieldWorker {
      _id
      fullName
    }
    startingBalance
    balance {
      balance
      incoming
      outgoing
      updatedBy
      updatedAt
    }
    adoptionId
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allAccountsQuery = gql`
  query AllAccounts($first: Int, $after: String, $filters: AccountFilters, $sortBy: [SortBy]) {
    allAccounts(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${accountListAttributes}
      }
    }
  }
`;

export const extractAllAccounts = (data) => data.allAccounts;

const transferListAttributes = gql`
  {
    _id
    description
    pending
    amount
    tags
    flag
    fromAccountId
    fromAccount {
      _id
      name
      description
      type
    }
    toAccountId
    toAccount {
      _id
      name
      description
      type
    }
    toContactId
    toContact {
      _id
      fullName
    }
    type
    allocationId
    adoptionId
    originalDonationId
    payoutId
    originalDate
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allTransfersQuery = gql`
  query AllTransfers($first: Int, $after: String, $filters: TransferFilters, $sortBy: [SortBy]) {
    allTransfers(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${transferListAttributes}
      }
    }
  }
`;

export const extractAllTransfers = (data) => data.allTransfers;

export const accountFullAttributes = gql`
  {
    _id
    name
    description
    tags
    fundId
    fund {
      _id
      name
    }
    external
    type
    regionCode
    regionName
    peopleGroupId
    peopleGroup {
      _id
      country
      nameAcrossCountries
      primaryThumbnailUrl
    }
    regionalCoordId
    regionalCoord {
      _id
      fullName
      primaryThumbnailUrl
    }
    fieldWorkerId
    fieldWorker {
      _id
      fullName
      primaryThumbnailUrl
    }
    startingBalance
    balance {
      balance
      incoming
      outgoing
      updatedBy
      updatedAt
    }
    adoptionId
    projectId
    holisticProject {
      _id
      projectName
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const accountQuery = gql`
  query Account($_id: ID!) {
    account(_id: $_id) ${accountFullAttributes}
  }
`;

export const extractAccount = (data) => data && data.account;

const AccountUpdateAttributes = gql`
{
  crud
  id
  new ${accountFullAttributes}
}
`;

export const accountUpdatesSubscription = gql`
  subscription AccountUpdates($_id: ID!) {
    accountUpdates(_id: $_id) ${AccountUpdateAttributes}
  }
`;

const TransferUpdateAttributes = gql`
{
  crud
  id
  new ${transferListAttributes}
}
`;

export const transferUpdatesSubscription = gql`
  subscription TransferUpdates($filters: TransferUpdateFilters!) {
    transferUpdates(filters: $filters) ${TransferUpdateAttributes}
  }
`;
