import DateFormItem from '@aims/shared/components/DateFormItem';
import { Checkbox, Form, Input, InputNumber } from 'antd';
import React from 'react';
import { TransferTypes } from '../constants';
import SelectAccount from './SelectAccount';
import SelectTransferType from './SelectTransferType';
import DollarX4InputNumber from './DollarX4InputNumber';
import TransferFlagsFormItem from './TransferFlagsFormItem';

function EditTransferFormItems({ form, transfer, saving }) {
  return (
    <>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: 'This field is require' }]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item label="Transfer Type" name="type">
        <SelectTransferType disabled={saving || transfer} />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type
        }
        noStyle
      >
        {({ getFieldValue }) => (
          <>
            {[
              TransferTypes.INTERNAL.key,
              TransferTypes.WITHDRAWAL.key,
            ].includes(getFieldValue('type')) && (
              <Form.Item
                label="From Account"
                name="fromAccountId"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectAccount
                  disabled={saving || transfer}
                  filters={{ external: false }}
                />
              </Form.Item>
            )}
            {[TransferTypes.DEPOSIT.key].includes(getFieldValue('type')) && (
              <Form.Item
                label="From Account"
                name="fromAccountId"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectAccount
                  disabled={saving || transfer}
                  filters={{ external: true }}
                />
              </Form.Item>
            )}
            {[TransferTypes.INTERNAL.key, TransferTypes.DEPOSIT.key].includes(
              getFieldValue('type'),
            ) && (
              <Form.Item
                label="To Account"
                name="toAccountId"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectAccount
                  disabled={saving || transfer}
                  filters={{ external: false }}
                />
              </Form.Item>
            )}
            {[TransferTypes.WITHDRAWAL.key].includes(getFieldValue('type')) && (
              <Form.Item
                label="To Account"
                name="toAccountId"
                rules={[
                  { required: true, message: 'Please select an account' },
                ]}
              >
                <SelectAccount
                  disabled={saving || transfer}
                  filters={{ external: true }}
                />
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
      <Form.Item label="Amount (USD)" name="amount" initialValue={0}>
        <DollarX4InputNumber disabled={saving || transfer} />
      </Form.Item>
      <TransferFlagsFormItem form={form} name="toAccountId" />
      <DateFormItem
        label="Original Date"
        name="originalDate"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
        disabled={saving}
      />
      <Form.Item name="pending" valuePropName="checked" initialValue={false}>
        <Checkbox>Pending</Checkbox>
      </Form.Item>
    </>
  );
}

export default EditTransferFormItems;
