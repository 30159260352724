import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DisplayAccountA10 from './DisplayAccountA10';
import DisplayAdoptionA10 from './DisplayAdoptionA10';
import DisplayPaymentA10 from './DisplayPaymentA10';
import DisplayRecurringPaymentA10 from './DisplayRecurringPaymentA10';
import {
  ColumnNames,
  HolisticPoolAccount,
  TableTitles,
  Titles,
  UpgSponsorshipPoolAccountId,
  associateDonationWithAdoptionMutation,
  donationXHolisticSponsorshipMutation,
} from './constants';
import useAccount from '../../accounting/accounts-j25/use-account';
import DisplayProjectA10 from './DisplayProjectA10';

const { Text, Title } = Typography;

const Accounts = {
  holistic: HolisticPoolAccount,
  upg: UpgSponsorshipPoolAccountId,
};

const AddMores = {
  holistic: 'Add Another Holistic Project',
  upg: 'Add Another UPG Sponsorship',
};

const AddPoolLabels = {
  holistic: 'Add Holistic Sponsorship Pool',
  upg: 'Add UPG Sponsorship Pool',
};

const ContactWithObjects = {
  holistic: (contact) =>
    `Associate the contact ${contact.fullName} with this holistic project`,
  upg: (contact) =>
    `Associate the contact ${contact.fullName} with this UPG sponsorship`,
};

const ContactWithPgs = {
  holistic: (contact) =>
    `Associate the contact ${contact.fullName} with this holistic project's people group(s)`,
  upg: (contact) =>
    `Associate the contact ${contact.fullName} with this UPG sponsorship's people group`,
};

const FinalQuestions = {
  holistic: (isRecurring, payment) =>
    !isRecurring
      ? `Would you like to assocate the above holistic project(s) with the selected
                  donation?  Once you do, ${displayMoney(
                    payment.usdCentsAmount / 100,
                  )}
                  will be transfered to the appropriate account(s).`
      : `Would you like to assocate the above holistic project(s) with the selected
                  recurring donation?  Future donations associated with this recurring donation will be suggest the selected holistic project(s)`,
  upg: (isRecurring, payment) =>
    !isRecurring
      ? `Would you like to assocate the above sponsorship(s) with the selected
                  donation?  Once you do, ${displayMoney(
                    payment.usdCentsAmount / 100,
                  )}
                  will be transfered to the appropriate account(s).`
      : `Would you like to assocate the above sponsorship(s) with the selected
                  recurring donation?  Future donations associated with this recurring donation will suggest the selected sponsorship(s)`,
};

function AdmSummaryPageA10({
  setPage,
  payment,
  contact,
  isRecurring,
  adoptionItems,
  onRemoveAdoption,
  onAddAdoptionItem,
  onCancel,
  onDone,
  fundType,
}) {
  const poolAccount = useAccount(Accounts[fundType]);
  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    const values = {};
    if (adoptionItems) {
      adoptionItems.forEach((a) => {
        if (a.adoption) {
          values[a.adoption._id] = {
            adoptionId: a.adoption._id,
            percent: (a.percent * 100).toFixed(1),
            rawPercent: a.percent,
            donation: (a.usdCentsAmount / 100).toFixed(2),
          };
        } else if (a.account) {
          values[a.account._id] = {
            accountId: a.account._id,
            percent: (a.percent * 100).toFixed(1),
            rawPercent: a.percent,
            donation: (a.usdCentsAmount / 100).toFixed(2),
          };
        } else if (a.project) {
          values[a.project._id] = {
            projectId: a.project._id,
            percent: (a.percent * 100).toFixed(1),
            rawPercent: a.percent,
            donation: (a.usdCentsAmount / 100).toFixed(2),
          };
        }
      });
    }
    setFormValues(values);
  }, [adoptionItems]);

  const handlePercentUpdate = useCallback(
    (record, value) => {
      const v = value.replace(/[^0-9.]/, '');
      let error;
      let n = formValues[record._id] && formValues[record._id].rawPercent;
      let d = formValues[record._id] && formValues[record._id].donation;
      try {
        n = Number(v);
        if (isNaN(n)) {
          error = 'Invalid number';
        } else {
          n = n / 100;
          d = ((payment.usdCentsAmount / 100) * n).toFixed(2);
        }
      } catch (err) {
        error = err.message;
      }
      setFormValues({
        ...formValues,
        [record._id]: {
          ...formValues[record._id],
          percent: v,
          rawPercent: n,
          donation: d,
          error,
        },
      });
    },
    [formValues, payment],
  );

  const handleAmountUpdate = useCallback(
    (record, value) => {
      const v = value.replace(/[^0-9.]/, '');
      let error;
      let n;
      let d = formValues[record._id] && formValues[record._id].percent;
      let rawPercent =
        formValues[record._id] && formValues[record._id].rawPercent;
      try {
        n = Number(v);
        if (isNaN(n)) {
          error = 'Invalid number';
        } else {
          rawPercent = n / (payment.usdCentsAmount / 100);
          d = (rawPercent * 100).toFixed(1);
        }
      } catch (err) {
        error = err.message;
      }
      setFormValues({
        ...formValues,
        [record._id]: {
          ...formValues[record._id],
          percent: d,
          rawPercent: rawPercent,
          donation: v,
          error,
        },
      });
    },
    [formValues, payment],
  );

  const [generalError, generalWarning, remainingPercent] = useMemo(() => {
    let error;
    let warning;
    let total = 0;
    const values = Object.values(formValues);
    for (let i = 0; i < values.length; i += 1) {
      const r = values[i];
      if (r.error) {
        error = 'Please fix the errors above';
        break;
      }
      total += r.rawPercent;
    }
    const remaining = 1.0 - total;
    if (!error && total > 1.0) {
      error = 'Total is greater than donation amount';
    }
    if (!error && total < 1.0 && !(total === 0 && isRecurring)) {
      error = `Total (${(total * 100).toFixed(
        2,
      )}%) is less than the donation amount.`;
    }
    return [error, warning, remaining];
  }, [formValues, isRecurring]);

  const [saving, setSaving] = useState(false);
  const [associateDonationWithAdoption] = useMutation(
    associateDonationWithAdoptionMutation,
  );
  const [donationXHolisticSponsorship] = useMutation(
    donationXHolisticSponsorshipMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        if (fundType === 'upg') {
          const allocations = Object.values(formValues).map((v) => {
            return {
              accountId: v.accountId,
              adoptionId: v.adoptionId,
              percent: v.rawPercent,
            };
          });
          await associateDonationWithAdoption({
            variables: {
              params: {
                paymentId: payment._id,
                isRecurring,
                allocations,
                ...values,
              },
            },
          });
        } else if (fundType === 'holistic') {
          const allocations = Object.values(formValues).map((v) => {
            return {
              accountId: v.accountId,
              projectId: v.projectId,
              percent: v.rawPercent,
            };
          });
          await donationXHolisticSponsorship({
            variables: {
              params: {
                paymentId: payment._id,
                isRecurring,
                allocations,
              },
            },
          });
        }
        notification.success({
          message: 'Success',
          description: 'Donation successfully allocated.',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error allocating donation.',
        });
      }
      setSaving(false);
    },
    [
      formValues,
      isRecurring,
      payment,
      associateDonationWithAdoption,
      onDone,
      donationXHolisticSponsorship,
      fundType,
    ],
  );

  const title = TableTitles[fundType];
  const addAnotherText = AddMores[fundType];

  const [form] = Form.useForm();

  return (
    <>
      <Title level={3} style={{ marginBottom: 0 }}>
        {Titles[fundType]}
      </Title>
      <Title
        level={5}
        style={{
          marginBottom: 4,
          marginTop: 0,
          color: sharedSettings.colors.text,
        }}
      >
        Summary
      </Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: -16,
          marginLeft: -16,
          marginBottom: 4,
        }}
      >
        <Button
          key="back"
          type="text"
          onClick={() => setPage('contact')}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
      </div>
      {isRecurring ? (
        <DisplayRecurringPaymentA10 payment={payment} />
      ) : (
        <DisplayPaymentA10 payment={payment} />
      )}
      <Title level={4} style={{ marginTop: 8 }}>
        {title}
      </Title>
      <Table
        dataSource={adoptionItems}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No selected sponsorships"
            />
          ),
        }}
      >
        <Table.Column
          title={ColumnNames[fundType]}
          dataIndex="sponsorshipOrAccount"
          render={(text, record) => (
            <>
              {record.account && <DisplayAccountA10 record={record.account} />}
              {record.adoption && (
                <DisplayAdoptionA10 record={record.adoption} />
              )}
              {record.project && <DisplayProjectA10 record={record.project} />}
            </>
          )}
        />
        <Table.Column
          title="Percent"
          dataIndex="percent"
          width={140}
          render={(text, record) => {
            return (
              <>
                <div>
                  <Input
                    value={
                      formValues[record._id] && formValues[record._id].percent
                    }
                    onChange={(e) =>
                      handlePercentUpdate(record, e.target.value)
                    }
                    addonAfter={<PercentageOutlined />}
                  />
                </div>
                {formValues[record._id] && formValues[record._id].error && (
                  <div>
                    <Text type="danger">{formValues[record._id].error}</Text>
                  </div>
                )}
              </>
            );
          }}
        />
        <Table.Column
          title="Amount"
          dataIndex="donation"
          width={160}
          render={(text, record) => {
            return (
              <Input
                value={
                  formValues[record._id] && formValues[record._id].donation
                }
                onChange={(e) => handleAmountUpdate(record, e.target.value)}
                addonBefore="$"
              />
            );
          }}
        />
        <Table.Column
          title=""
          dataIndex="actions"
          render={(text, record) => {
            return (
              <Tooltip title="Remove">
                <Button
                  onClick={() => onRemoveAdoption(record)}
                  icon={<DeleteOutlined />}
                  type="text"
                />
              </Tooltip>
            );
          }}
        />
      </Table>
      {generalError && (
        <div
          style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}
        >
          <Text style={{ textAlign: 'center' }} type="danger">
            {generalError}
          </Text>
        </div>
      )}
      {generalWarning && (
        <div
          style={{ paddingTop: 16, display: 'flex', justifyContent: 'center' }}
        >
          <Text style={{ textAlign: 'center' }} type="warning">
            {generalWarning}
          </Text>
        </div>
      )}
      <div
        style={{
          paddingTop: 16,
          marginBottom: 32,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          onClick={() => setPage('sponsorship')}
          loading={saving}
        >
          {addAnotherText}
        </Button>
        {generalError && remainingPercent > 0.0 && poolAccount?.account && (
          <Button
            type="default"
            onClick={() => onAddAdoptionItem(poolAccount.account, 'account')}
            loading={saving}
            style={{ marginTop: 16 }}
          >
            {AddPoolLabels[fundType]}
          </Button>
        )}
      </div>
      <Form layout="vertical" form={form} style={{}} onFinish={handleFinish}>
        <div>
          <Form.Item
            name="associateContactWithAdoption"
            valuePropName="checked"
            noStyle
            initialValue={true}
          >
            <Checkbox disabled={fundType === 'holistic'}>
              {ContactWithObjects[fundType](contact)}
            </Checkbox>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="associateContactWithPeopleGroup"
            valuePropName="checked"
            noStyle
            initialValue={true}
          >
            <Checkbox disabled={fundType === 'holistic'}>
              {ContactWithPgs[fundType](contact)}
            </Checkbox>
          </Form.Item>
        </div>
        <div style={{ textAlign: 'center', marginTop: 16, marginBottom: 32 }}>
          <Text>{FinalQuestions[fundType](isRecurring, payment)}</Text>
          <Title level={4} type="warning">
            Warning
          </Title>
          {!isRecurring ? (
            <Text type="warning">This action cannot be undone.</Text>
          ) : (
            <Text type="warning">
              This action will only affect future payments.
            </Text>
          )}
        </div>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ width: 100, marginRight: 16 }}
              disabled={saving}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              style={{ width: 100 }}
              disabled={!!generalError}
              loading={saving}
              type="primary"
              htmlType="submit"
            >
              Yes
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default AdmSummaryPageA10;
