import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { displayMoney } from '@aims/shared/shared/utils';
import { Button, Card, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import settings from '../../../../../settings';
import {
  allTransfersQuery,
  extractAllTransfers,
} from '../../../../accounting/accounts-j25/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import sharedSettings from '@aims/shared/sharedSettings';

const { Paragraph, Title } = Typography;

function MyListItem({ label, value }) {
  return (
    <div style={{ marginRight: 32 }}>
      <div>{label}</div>
      <div style={{ fontWeight: 600 }}>{value}</div>
    </div>
  );
}

function ViewInstallmentRow({ hpInstallment, setRefetch, onDeletePayment }) {
  const [sortedBy, setSortedBy] = useState('originalDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    hpInstallmentId: hpInstallment._id,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateformat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
  });

  const {
    error,
    loading,
    data: transfers,
    search: transferSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTransfersQuery,
    extract: extractAllTransfers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: hpInstallment._id,
  });

  useEffect(
    () => setRefetch(hpInstallment._id, refetch),
    [hpInstallment, refetch, setRefetch],
  );

  return (
    <div
      style={{
        paddingLeft: 32,
      }}
    >
      <Title level={4}>Payments</Title>
      {transfers?.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {transfers.map((transfer) => (
            <Card
              key={transfer._id}
              style={{
                marginBottom: 16,
                marginRight: 16,
                border: `1px solid ${sharedSettings.colors.backgroundGray}`,
              }}
            >
              <div style={{ display: 'flex' }}>
                <MyListItem
                  label="Paid On"
                  value={dateformat.format(new Date(transfer.originalDate))}
                />
                <MyListItem
                  label="Amount"
                  value={displayMoney(transfer.amount / 10000)}
                />
                <div style={{ flex: 1 }} />
                <Tooltip title="Delete payment">
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => onDeletePayment(transfer)}
                  />
                </Tooltip>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div>
          <Paragraph>No payments yet</Paragraph>
        </div>
      )}
    </div>
  );
}

export default ViewInstallmentRow;
