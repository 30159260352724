import sharedSettings from '@aims/shared/sharedSettings';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import React, { useMemo } from 'react';

export const HolisticChecklistItems = {
  PROPOSAL: {
    key: 'PROPOSAL',
    label: 'Proposal received & approved',
  },
  NUMBER: {
    key: 'NUMBER',
    label: 'Project # assigned',
  },
  ACCOUNT: {
    key: 'ACCOUNT',
    label: 'Accounting initialized',
  },
  DETAILS: {
    key: 'DETAILS',
    label: 'Project & loan details complete',
  },
  LOAN_AGREEMENT: {
    key: 'LOAN_AGREEMENT',
    label: 'Loan agreement created & signed',
  },
  PAYMENT_PLAN: {
    key: 'PAYMENT_PLAN',
    label: 'Payment plan created and locked',
  },
  FUNDED: {
    key: 'FUNDED',
    label: 'Project fully funded',
  },
  PAYOUTS: {
    key: 'PAYOUTS',
    label: 'Loan Payout',
  },
  REPAID: {
    key: 'REPAID',
    label: 'Project loan fully repaid',
  },
  REPORTS: {
    key: 'REPORTS',
    label: 'Quarterly reports up-to-date',
  },
  PAYMENTS: {
    key: 'PAYMENTS',
    label: 'Payments up-to-date',
  },
  DEBRIEF: {
    key: 'DEBRIEF',
    label: 'Project debrief complete',
  },
};

function getItemProps(a) {
  return {
    children: (
      <>
        <p style={{ marginBottom: 0 }}>
          {HolisticChecklistItems[a.name]?.label}
        </p>
        {a.notes && <p style={{ marginBottom: 0 }}>{a.notes}</p>}
      </>
    ),
    dot: a.isComplete ? <CheckCircleFilled /> : <CloseCircleFilled />,
    color: a.isComplete
      ? sharedSettings.colors.green
      : sharedSettings.colors.red,
  };
}

function useProjectChecklistItems(project) {
  const items = useMemo(() => {
    const details = (project.auditDetails || []).filter(
      (a) => a.stage === project.stage,
    );
    const ll = details.length;
    return details.map((a, index) => ({
      ...a,
      ...getItemProps(a),
      style: {
        padding: index === ll - 1 ? 0 : undefined,
      },
    }));
  }, [project]);
  const allDone = useMemo(() => {
    return items.reduce((prev, curr) => {
      return prev && curr.isComplete === true;
    }, true);
  }, [items]);
  return {
    items,
    allDone,
  };
}

export default useProjectChecklistItems;
