import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';
import { updateHolisticProjectMutation } from '../../../constants';

const { Text, Title, Paragraph } = Typography;

const LockPaymentPlanBtn = ({ project }) => {
  const [saving, setSaving] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(async () => {
    setSaving(true);
    try {
      await updateHolisticProject({
        variables: {
          holisticProject: {
            _id: project._id,
            paymentPlanLocked: !project.paymentPlanLocked,
          },
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Payment plan locked.',
      });
      setShowingModal(null);
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setSaving(false);
  }, [updateHolisticProject, project]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 16,
        }}
      >
        {project.paymentPlanLocked ? (
          <>
            <Paragraph style={{ marginBottom: 8 }}>To make changes</Paragraph>
            <Button
              loading={saving}
              icon={<UnlockOutlined />}
              onClick={() => setShowingModal(true)}
            >
              Unlock Payment Plan
            </Button>
          </>
        ) : (
          <>
            <Paragraph style={{ marginBottom: 8 }}>
              To prevent accidental changes
            </Paragraph>
            <Button
              loading={saving}
              onClick={() => setShowingModal(true)}
              icon={<LockOutlined />}
            >
              Lock Payment Plan
            </Button>
          </>
        )}
      </div>

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!saving}
        destroyOnClose={true}
        maskClosable={!saving}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 16, textAlign: 'center' } }}
      >
        <TaskSomethingForm
          title={
            project.paymentPlanLocked
              ? 'Unlock Payment Plan'
              : 'Lock Payment Plan'
          }
          form={form}
          onFinish={handleFinish}
          onCancel={() => setShowingModal(false)}
          saving={saving}
          saveText="Yes"
        >
          {project.paymentPlanLocked ? (
            <Paragraph>
              Are you sure you want to unlock this payment plan?
            </Paragraph>
          ) : (
            <Paragraph>
              Are you sure you want to lock this payment plan? You will be
              unable to make changes while it is locked.
            </Paragraph>
          )}
        </TaskSomethingForm>
      </Modal>
    </>
  );
};

export default LockPaymentPlanBtn;
