import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Skeleton, Tabs } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createHolisticProjectAction,
  deleteHolisticProjectAction,
  updateHolisticProjectAction,
} from '../../../redux-store/holistic-projects-store';
import AnyTasks from '../../tasks/AnyTasks';
import {
  extractHolisticProjectForAdmin,
  holisticProjectForAdminQuery,
  holisticProjectUpdatesForAdminSubscription,
} from '../constants';
import HolisticProjectTitle from './HolisticProjectTitle';
import AccountingTab from './tabs/AccountingTab/AccountingTab';
import HolisticProjectDetailsTab from './tabs/HolisticProjectDetailsTab/HolisticProjectDetailsTab';
import HolisticProjectPhotos from './tabs/HolisticProjectPhotosTab/HolisticProjectPhotos';
import LoanAgreementTab from './tabs/LoanAgreementsTab/LoanAgreementsTab';
import ViewProposalTab from './tabs/ViewProposalTab/ViewProposalTab';
import HolisticQuarterlyReportsTabs from '../../holistic-quarterly-reports/HolisticQuarterlyReportsTabs';
import HolisticActivityTab from './tabs/HolisticActivityTab';
import ViewPaymentPlan from './tabs/PaymentPlanTab/ViewPaymentPlan';
import HolisticStatusTab from './tabs/HolisticStatusTab/HolisticStatusTab';
import HpPhotosTab from './tabs/HolisticProjectPhotosTab/HpPhotosTab';

const queryId = 'viewHolisticProject';

function ViewHolisticProject() {
  const history = useHistory();

  const { _id, tab } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: holisticProjectUpdatesForAdminSubscription,
    createAction: createHolisticProjectAction,
    updateAction: updateHolisticProjectAction,
    deleteAction: deleteHolisticProjectAction,
    extractSomething: (resp) => resp?.data?.holisticProjectUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: holisticProjectForAdminQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !_id,
    showError: false,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    _id,
    queryId,
  );

  const handleBack = useCallback(
    () => history.push(`/holistic-projects`),
    [history],
  );

  useEffect(() => {
    if (!tab) {
      history.push(`/holistic-project/view/${_id}/details`);
    }
  }, [tab, history, _id]);
  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="View Holistic Project"
      >
        <CSPageHeader
          titleComponent={<HolisticProjectTitle project={holisticProject} />}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Holistic Projects
            </Button>,
          ]}
          style={{ marginBottom: 24 }}
        />
        <Tabs
          defaultActiveKey="details"
          activeKey={tab}
          onChange={(key) =>
            history.push(`/holistic-project/view/${_id}/${key}`)
          }
        >
          {holisticProject ? (
            <>
              <Tabs.TabPane tab="Status" key="status">
                <HolisticStatusTab project={holisticProject} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Details" key="details">
                <HolisticProjectDetailsTab project={holisticProject} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tasks & Activity" key="activity">
                <HolisticActivityTab project={holisticProject} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Reports" key="reports">
                <HolisticQuarterlyReportsTabs
                  holisticProjectId={_id}
                  baseUrl={`/holistic-project/view/${_id}/reports`}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Photos" key="pictures">
                <HpPhotosTab project={holisticProject} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Proposal" key="proposal">
                {holisticProject.applicationId ? (
                  <ViewProposalTab proposalId={holisticProject.applicationId} />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Project Proposal"
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Loan Agreements" key="loan-agreement">
                <LoanAgreementTab project={holisticProject} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Payment Plan" key="payment-plan">
                <ViewPaymentPlan project={holisticProject} />
              </Tabs.TabPane>
              {/* <Tabs.TabPane tab="Accounting" key="accounting">
                <AccountingTab project={holisticProject} queryId={queryId} />
              </Tabs.TabPane> */}
            </>
          ) : (
            <div style={{ maxWidth: 800 }}>
              <Tabs.TabPane tab="Details" key="details">
                <Card>
                  <Skeleton active paragraph />
                </Card>
                <Skeleton active paragraph />
              </Tabs.TabPane>
            </div>
          )}
        </Tabs>
        <div style={{ minHeight: 300 }} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default ViewHolisticProject;
