import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Progress, Typography } from 'antd';
import React from 'react';

const { Text, Title } = Typography;

function ProjectPaybackStatus({ project }) {
  return (
    <>
      {project.paybackTotalX4 &&
        project.paybackBalance !== undefined &&
        [
          HolisticProjectStages.ACTIVE.key,
          HolisticProjectStages.COMPLETE.key,
          HolisticProjectStages.FAILED.key,
        ].includes(project.stage) && (
          <>
            <Title level={3}>Loan Repayment</Title>
            <Progress percent={Math.round(project.paybackPercent / 100)} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.paybackBalance) / 10000)}
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.paybackTarget) / 10000)}
                </Text>
              </div>
            </div>
          </>
        )}
    </>
  );
}

export default ProjectPaybackStatus;
