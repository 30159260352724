import React from 'react';
import HProjectDetailsCard from './HProjectDetailsCard';
import HolisticProjectPhotos from '../HolisticProjectPhotosTab/HolisticProjectPhotos';
import { Typography } from 'antd';

const { Title } = Typography;

function HolisticProjectDetailsTab({ project }) {
  return (
    <>
      <HProjectDetailsCard holisticProject={project} />
      <Title level={3} style={{ marginBottom: 0 }}>
        Photos
      </Title>
      <div style={{ marginBottom: 24 }}>
        <HolisticProjectPhotos project={project._id} isSummary />
      </div>
    </>
  );
}

export default HolisticProjectDetailsTab;
