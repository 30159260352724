import { BankOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import InitiateAccountingModal from './InitiateAccountingModal';

function InitializeAccountingBtn({ project }) {
  const [showModal, setShowModal] = useState();

  const showInitiateBtn = useMemo(() => {
    return (
      project &&
      (!project.projectNo ||
        !project.accountId ||
        !project.fundingTarget ||
        !project.payoutAccountId)
    );
  }, [project]);

  return (
    <>
      {project && showInitiateBtn && (
        <>
          <div
            style={{
              marginBottom: 32,
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              icon={<BankOutlined />}
              onClick={() => setShowModal(true)}
            >
              Initiate Holistic Project Accounting
            </Button>
          </div>
          <InitiateAccountingModal
            showingModal={showModal}
            setShowingModal={setShowModal}
            project={project}
          />
        </>
      )}
    </>
  );
}

export default InitializeAccountingBtn;
