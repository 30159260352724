import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney } from '@aims/shared/shared/utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Card, notification, Tag, Typography } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import CompletePayoutTxModal from './CompletePayoutTxModal';
import UndoInitiatePayoutBtn from './UndoInitiatePayoutBtn';

const { Title, Text, Paragraph } = Typography;

const initiateHolisticProjectPayoutMutation = gql`
  mutation InitiateHolisticProjectPayout($_id: ID!) {
    initiateHolisticProjectPayout(_id: $_id)
  }
`;

const transferQuery = gql`
  query TransferQuery($_id: ID!) {
    transfer(_id: $_id) {
      _id
      description
      tags
      flag
      pending
      amount
      fromAccountId
      toAccountId
      toContactId
      type
      originalDate
      holisticProjectId
      createdAt
      updatedAt
    }
  }
`;

function TxCard({ txId, title, onComplete, refreshTxs }) {
  const { data, loading, error, refetch } = useQuery(transferQuery, {
    variables: { _id: txId },
    fetchPolicy: 'network-only',
    skip: !txId,
  });
  refreshTxs[txId] = refetch;
  const transfer = data?.transfer;
  return (
    <Card
      style={{ marginRight: 16, width: 240 }}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 240,
        },
      }}
      bordered={false}
    >
      <div style={{ fontWeight: 600, fontSize: 18 }}>{title}</div>
      <div style={{ flex: 1 }} />
      <div>
        {transfer?.amount && (
          <div style={{ marginBottom: 8, fontWeight: 600 }}>
            {displayMoney(transfer.amount / 10000)}
          </div>
        )}
        {transfer?.pending ? (
          <>
            <div>
              <Tag
                style={{ fontSize: 16, padding: '4px 12px', marginBottom: 16 }}
                color="orange"
              >
                Pending
              </Tag>
            </div>
          </>
        ) : (
          <Tag
            style={{ fontSize: 16, padding: '4px 12px', marginBottom: 16 }}
            color="green"
          >
            Complete
          </Tag>
        )}
      </div>
      <div style={{ flex: 1 }} />
      <div>
        {transfer?.pending && (
          <Button type="primary" onClick={() => onComplete(txId, name)}>
            Mark Complete
          </Button>
        )}
      </div>
    </Card>
  );
}

function InitiateLoanSection({ project }) {
  const [showPayoutModal, setShowPayoutModal] = useState();
  const [loading, setLoading] = useState(false);
  const [initiateHolisticProjectPayout] = useMutation(
    initiateHolisticProjectPayoutMutation,
  );

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await initiateHolisticProjectPayout({
        variables: {
          _id: project._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Loan payout initiated.',
      });
      setShowPayoutModal(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setLoading(false);
  }, [initiateHolisticProjectPayout, project]);

  const amounts = useMemo(() => {
    const rc = Math.round(project.fundingTarget * 0.05);
    const payout = project.loanTotalX4;
    const and = project.fundingTarget - rc - payout;
    return {
      rc,
      and,
      payout,
    };
  }, [project]);

  const [completing, setCompleting] = useState();
  const onCancelComplete = () => setCompleting(undefined);
  const onCompleteTx = (txId, name) => setCompleting({ txId, name });
  const onFinishComplete = (txId) => {
    refreshTxs[txId]?.();
    setCompleting(undefined);
  };
  const refreshTxs = useRef({});

  return (
    <>
      {project.stage === HolisticProjectStages.FUNDED.key && (
        <>
          <div style={{ display: 'flex' }}>
            <Title level={3} style={{ marginBottom: 16 }}>
              Loan Payout
            </Title>
            <div style={{ flex: 1 }} />
            <UndoInitiatePayoutBtn project={project} />
          </div>
          <div style={{ textAlign: 'center' }}>
            {!project.txToAndId ||
            !project.txToRegionalCoordId ||
            !project.txToPayoutId ? (
              <>
                <Paragraph style={{ fontSize: 14 }}>
                  This loan payout has not been initiated yet.
                </Paragraph>
                <Paragraph>
                  <Button
                    onClick={() => setShowPayoutModal(true)}
                    type="primary"
                  >
                    Initiate Loan Payout
                  </Button>
                </Paragraph>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TxCard
                  txId={project.txToAndId}
                  title="Payout to A&D"
                  onComplete={onCompleteTx}
                  refreshTxs={refreshTxs}
                />
                <TxCard
                  txId={project.txToRegionalCoordId}
                  title={`Payout to Regional Coordinator`}
                  onComplete={onCompleteTx}
                  refreshTxs={refreshTxs}
                />
                <TxCard
                  txId={project.txToPayoutId}
                  title="Payout to Loan Recipient"
                  onComplete={onCompleteTx}
                  refreshTxs={refreshTxs}
                />
              </div>
            )}
          </div>
          <YesNoModalJ10
            title="Initiate Loan Payout"
            yesText="Yes"
            noText="Cancel"
            onYes={handleFinish}
            onNo={() => setShowPayoutModal(false)}
            visible={showPayoutModal}
            loading={loading}
          >
            <Paragraph
              style={{ marginBottom: 8 }}
            >{`This will initiate transactions from this project's account to General A&D, the regional coordinator, ${project.regionalCoordinator?.fullName}, and this project's loan recipient.`}</Paragraph>
            <ul style={{ textAlign: 'left' }}>
              <li>{`${displayMoney(amounts.and / 10000)} to A&D`}</li>
              <li>{`${displayMoney(amounts.rc / 10000)} to ${
                project.regionalCoordinator?.fullName
              }`}</li>
              <li>{`${displayMoney(
                amounts.payout / 10000,
              )} to loan recipient`}</li>
            </ul>
            <Paragraph
              style={{ marginBottom: 8 }}
            >{`Would you like to continue?`}</Paragraph>
          </YesNoModalJ10>
          <CompletePayoutTxModal
            visible={completing}
            onCancel={onCancelComplete}
            onFinish={onFinishComplete}
          />
        </>
      )}
    </>
  );
}

export default InitiateLoanSection;
