import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Progress, Typography } from 'antd';
import React from 'react';

const { Text, Title } = Typography;

function ProjectFundingStatus({ project }) {
  return (
    <>
      {project.fundingTarget !== undefined &&
        project.fundingBalance !== undefined &&
        [
          HolisticProjectStages.FUNDED.key,
          HolisticProjectStages.FUNDING.key,
          HolisticProjectStages.COMPLETE.key,
          HolisticProjectStages.FAILED.key,
        ].includes(project.stage) && (
          <>
            <Title level={3}>Project Funding</Title>
            <Progress percent={Math.round(project.fundingPercent / 100)} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.fundingBalance) / 10000)}
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.fundingTarget) / 10000)}
                </Text>
              </div>
            </div>
          </>
        )}
    </>
  );
}

export default ProjectFundingStatus;
